export const projects = [

    {
        "projectName": "Video Clip Sharer",
        "projectIcon": "/portfolio/video.png",
        "projectShortDescription": "Basic video editor developed using C#",
        "projectDescription": "Project 2 long description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/Video-Clip-Sharer/master/portfolio.md"
    },
    {
        "projectName": "League Bot",
        "projectIcon": "/portfolio/draven.png",
        "projectShortDescription": "Discord bot to provide utility for league of legends discord servers",
        "projectDescription": "This is my long leaguebot description.",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/LeagueBot/main/portfolio.md"
    },
    {
        "projectName": "Discord Voice Assistant",
        "projectIcon": "/portfolio/mic.png",
        "projectShortDescription": "Multi-purpose discord bot built to utilize speech recognition",
        "projectDescription": "This is my long leaguebot description.",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/discord-voice-assistant/main/README.md"
    },
    {
        "projectName": "Realtime Livestream Server",
        "projectIcon": "/portfolio/videoclip.png",
        "projectShortDescription": "Deployable low latency video live streaming server/website.",
        "projectDescription": "Project 3 long description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/realtime-livestream-server/main/portfolio.md"
    },
    {
        "projectName": "Restaurant Menu Services",
        "projectIcon": "/portfolio/RestaurantMenuLogo.png",
        "projectShortDescription": "Virtually host and create restaurant menus",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/restaurant-menu/main/portfolio.md"
    },
    {
        "projectName": "Portfolio",
        "projectIcon": "/portfolio/smile.png",
        "projectShortDescription": "Website portfolio built in ReactJS. Its where your at right now!",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/portfolio/master/portfolio.md"
    },
    {
        "projectName": "Login Page",
        "projectIcon": "/portfolio/memo.png",
        "projectShortDescription": "React.JS website that uses AWS Cognito for the backend.",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/login-page/master/portfolio.md"
    },
    {
        "projectName": "Nytelock Sample Page",
        "projectIcon": "/portfolio/sparkles.png",
        "projectShortDescription": "React.JS page to display photos.",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/nytelock-sample-page/master/portfolio.md"
    },
    {
        "projectName": "Glitter",
        "projectIcon": "/portfolio/glitter.png",
        "projectShortDescription": "Mock twitter clone crafted with a JavaScript, Tailwind, SQL, and sandman2.",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/cpsc349-project4/main/README.md"
    },
    {
        "projectName": "UFC Bot",
        "projectIcon": "/portfolio/fight.png",
        "projectShortDescription": "UFC Betting bot built using Odds Shark scraper.",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/ufc-bot/main/README.md"
    },
    {
        "projectName": "Spam Filter Bot",
        "projectIcon": "/portfolio/anti.png",
        "projectShortDescription": "Anti-Spam Bot built for discord servers using discord.js and Naive Baiyes Theorem.",
        "projectDescription": "Long portfolio description",
        "projectMarkDown": "https://raw.githubusercontent.com/quentinmay/spam-filter-bot/master/README.md"
    }
];


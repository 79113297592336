import './App.css';
import Header from "./components/Header"
import ProjectsList from "./components/ProjectsList"

// import { useState } from 'react'
function App() {
  return (
    <div className="App">
      <Header />
      <ProjectsList />
    </div>
  );
}

export default App;
